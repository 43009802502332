import React from 'react';
import Form from '../components/Form';

const Quotes = () => {

  return (
    <div className="Quotes">
      <Form />
    </div>
  );
};

export default Quotes;