import React from 'react';
import { useParams } from 'react-router-dom';
import FetchOrder from '../components/FetchOrder'; // Import the FetchQuote component

const SingleQuote = () => {
  const { poNumber } = useParams();
  
  return (
    <div className="Quotes">
      {/* Render other components and pass poNumber as required */}<FetchOrder poNumber={poNumber} />
    </div>
  );
};

export default SingleQuote;