import React from "react";
import "./ThankYouPage.css";

const ThankYouPage = ({ handleReturn, confirmationType }) => {
  return (
    <div className="container">
      <div className="thank-you-page">
        {confirmationType === "request" ? (
          <>
            <h1>Thanks for your request</h1>
            <p>We'll get onto this asap and be in touch within 24 hours.</p>
          </>
        ) : confirmationType === "delivery" ? (
          <>
            <h1>Thanks for confirming the delivery</h1>
            <p>Your submission has been received.</p>
          </>
        ) : null}
        <button onClick={handleReturn}>Return to Form</button>
      </div>
    </div>
  );
};

export default ThankYouPage;
