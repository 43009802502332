import React from "react";
const defaultSrc =
  "https://nationalsafetysigns.com.au/wp-content/uploads/2015/10/RD176-Custom-Road-signs.png";

const SignSection = ({ title, signData, handleQuantityChange }) => {
  return (
    <>
      <h2>{title}</h2>
      <div className="sign-section">
        {signData.map((sign, index) => (
          <div
            className={`grid-item sign-row ${
              sign.quantity > 0 ? "quantity-selected" : ""
            }`}
            key={sign.id}
          >
            <div className="image-column">
              <div className="image-wrapper">
                <img
                  src={sign.image ? sign.image : defaultSrc}
                  alt={sign.name ? sign.name : "Error: Could not be loaded"}
                />
              </div>
            </div>
            <div className="name-column">
              <span className="sign-name">
                {sign.name && sign.name.includes("-") ? sign.name.split("-")[0] : sign.name}
              </span>
              <span className="sign-size">
                {sign.name && sign.name.includes("-") ? sign.name.split("-")[1] : ""}
              </span>
            </div>
            <div className="quantity-column">
              <label>Qty:</label>
              <input
                type="number"
                min="0"
                value={sign.quantity > 0 ? sign.quantity : ""}
                onChange={(e) =>
                  handleQuantityChange(sign.id, parseInt(e.target.value))
                }
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SignSection;
