const signDataFirstTruck = [
  {
    "id": "151974864",
    "name": "Road Work Ahead - Corflute (600x600)",
    "image_url": "https://www.discountsafetysignsaustralia.com.au/wp-content/uploads/2017/02/bs2419-road-work-ahead.png.webp",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "20"
  },
  {
    "id": "115010981",
    "name": "End Road Work - Corflute (600x600)",
    "image_url": "https://www.discountsafetysignsaustralia.com.au/wp-content/uploads/2017/02/bs2418-end-road.png.webp",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "8"
  },
  {
    "id": "190607280",
    "name": "Road Closed - Corflute (600x600)",
    "image_url": "https://cdn.shopify.com/s/files/1/2439/4751/products/Road-Closed.jpg?v=1607738680&width=600",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "10"
  },
  {
    "id": "142432692",
    "name": "Road Closed Ahead - Corflute (600x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/road-closed-ahead-600x600.jpg",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "10"
  },
  {
    "id": "146465575",
    "name": "Local Traffic Only - Corflute (600x600)",
    "image_url": "https://cdn.shopify.com/s/files/1/2439/4751/products/Local-Traffic-Only.jpg?v=1607738141&width=600",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "10"
  },
  {
    "id": "187282206",
    "name": "On Side Road - Corflute (600x600)",
    "image_url": "https://cdn.shopify.com/s/files/1/2439/4751/products/OnSideRoad.jpg?v=1609976583&width=600",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "20"
  },
  {
    "id": "103396737",
    "name": "Footpath Closed - Corflute (600x600)",
    "image_url": "https://www.uags.com.au/assets/full/CF66FC.jpg?20200707030727",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "143900809",
    "name": "Use Other Footpath - Corflute (600x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/use-other-footpath-600x600.jpg",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "103838546",
    "name": "Footpath Closed Ahead - Corflute (600x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/foothpath-closed-ahead-600x600.jpg",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "178564930",
    "name": "Watch Your Step - Corflute (600x600)",
    "image_url": "https://www.uags.com.au/assets/full/CF66WYS.jpg?20200707030635",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "135021531",
    "name": "Detour - Corflute (600x600)",
    "image_url": "https://www.uags.com.au/assets/full/CF66D.jpg?20200707030635",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "16"
  },
  {
    "id": "115372677",
    "name": "Arrow - Corflute (600x600)",
    "image_url": "https://cdn.shopify.com/s/files/1/2439/4751/products/Arrow-Bold.jpg?v=1608158754&width=1100",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "16"
  },
  {
    "id": "138947886",
    "name": "Symbolic Traffic Controller - Corflute (600x600)",
    "image_url": "https://www.uags.com.au/assets/full/CF66TC.jpg?20200707030632",
    "price": "19.9",
    "artPrice": "17.9",
    "podQty": "12"
  },
  {
    "id": "196045041",
    "name": "Symbolic Worker - Corflute (600x600)",
    "image_url": "https://www.safetyhq.com.au/assets/thumbL/USS-SW606-M.jpg?20230504122556",
    "price": "19.9",
    "artPrice": "17.9",
    "podQty": "20"
  },
  {
    "id": "174649300",
    "name": "Speed Restriction 40km - Corflute (600x600)",
    "image_url": "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2009_3.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "8"
  },
  {
    "id": "109232277",
    "name": "Speed Restriction 50km - Corflute (600x600)",
    "image_url": "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2010_3.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "8"
  },
  {
    "id": "160164356",
    "name": "Speed Restriction 60km - Corflute (600x600)",
    "image_url": "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2011_3.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "8"
  },
  {
    "id": "127110790",
    "name": "Speed Restriction 70km - Corflute (600x600)",
    "image_url": "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2012_3.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "8"
  },
  {
    "id": "107705971",
    "name": "Speed Restriction 80km - Corflute (600x600)",
    "image_url": "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2013_3.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "8"
  },
  {
    "id": "126938612",
    "name": "Speed Restriction 100km - Corflute (600x600)",
    "image_url": "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2015_3.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "8"
  },
  {
    "id": "141062889",
    "name": "No Left Turn - Corflute (600x600)",
    "image_url": "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2017_3.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "6"
  },
  {
    "id": "159717497",
    "name": "No Right Turn - Corflute (600x600)",
    "image_url": "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2018_3.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "6"
  },
  {
    "id": "139687812",
    "name": "Yellow Blank - Corflute (600x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/yellow-blank.png",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "20"
  },
  {
    "id": "176965067",
    "name": "Lane Status Straight Closed - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-closed.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "122083391",
    "name": "Lane Status Straight Open - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-open.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "191541012",
    "name": "Lane Status Left Open - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-left-open.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "122994070",
    "name": "Lane Status Right Open - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-right-open.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "191546696",
    "name": "Lane Status Left Closed - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-left-closed.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "142885288",
    "name": "Lane Status Right Closed - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-right-closed.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "119792251",
    "name": "Lane Status Straight Open Left Closed - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-open-left-closed.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "111783019",
    "name": "Lane Status Straight Open Right Closed - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-open-right-closed.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "168938442",
    "name": "Lane Status Straight Left Open - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-left-open.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "117435610",
    "name": "Lane Status Straight Right Open - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-right-open.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "154742624",
    "name": "Lane Status Straight Left Closed - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-left-closed.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "144745405",
    "name": "Lane Status Straight Right Closed - Corflute (300x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-right-closed.jpg",
    "price": "12.5",
    "artPrice": "7.95",
    "podQty": "6"
  },
  {
    "id": "168828469",
    "name": "Symbolic Digger Man - Corflute (600x900)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/symbolic-digger-man-600x900.jpg",
    "price": "31.9",
    "artPrice": "29",
    "podQty": "8"
  },
  {
    "id": "117769822",
    "name": "40 Speed Ahead - Corflute (600x900)",
    "image_url": "https://www.uags.com.au/assets/full/CF6940A.jpg?20200707030623",
    "price": "29.9",
    "artPrice": "23",
    "podQty": "6"
  },
  {
    "id": "139431163",
    "name": "Speed Restriction 40km - Corflute (600x900)",
    "image_url": "https://cdn.shopify.com/s/files/1/2439/4751/products/40kmSpeed.jpg?v=1614135886&width=493",
    "price": "29.9",
    "artPrice": "23",
    "podQty": "6"
  },
  {
    "id": "154748803",
    "name": "Speed Restriction 60km - Corflute (600x900)",
    "image_url": "https://cdn.shopify.com/s/files/1/2439/4751/products/60kmSpeed.jpg?v=1614135864&width=493",
    "price": "29.9",
    "artPrice": "23",
    "podQty": "6"
  },
  {
    "id": "152605267",
    "name": "Speed Restriction 80km - Corflute (600x900)",
    "image_url": "https://cdn.shopify.com/s/files/1/2439/4751/products/80km-Speed.jpg?v=1664109236&width=493",
    "price": "29.9",
    "artPrice": "23",
    "podQty": "6"
  },
  {
    "id": "143396669",
    "name": "Speed Restriction 100km - Corflute (600x900)",
    "image_url": "https://cdn.shopify.com/s/files/1/2439/4751/products/100km-Speed.jpg?v=1664108082&width=493",
    "price": "29.9",
    "artPrice": "23",
    "podQty": "6"
  },
  {
    "id": "154766142",
    "name": "Road Work Ahead - Corflute (600x900)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/road-work-ahead-600x900.jpg",
    "price": "29.9",
    "artPrice": "23",
    "podQty": "6"
  },
  {
    "id": "162820621",
    "name": "End Road Work - Corflute (600x900)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/end-road-work-600x900.jpg",
    "price": "29.9",
    "artPrice": "23" ,
    "podQty": "8"
  },
  {
    "id": "109133635",
    "name": "Road Closed - Corflute (1200x600)",
    "image_url": "https://www.uags.com.au/assets/full/CF126RC.jpg?20200707030727",
    "price": "33.9",
    "artPrice": "27.95",
    "podQty": "6"
  },
  {
    "id": "120924403",
    "name": "Detour Ahead - Corflute (1200x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/detour-ahead-1200x600.jpg",
    "price": "33.9",
    "artPrice": "27.95",
    "podQty": "6"
  },
  {
    "id": "181268019",
    "name": "Detour - Corflute (1200x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/detour.png",
    "price": "33.9",
    "artPrice": "27.95",
    "podQty": "6"
  },
  {
    "id": "100871194",
    "name": "Road Work Ahead - Corflute (1200x600)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/roadwork-ahead.png",
    "price": "33.9",
    "artPrice": "27.95",
    "podQty": "6"
  },
  {
    "id": "183919202",
    "name": "Detour Right - Corflute (1200x300)",
    "image_url": "https://www.uags.com.au/assets/full/CF123DR.jpg?20200707030629",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "137452490",
    "name": "Detour Left - Corflute (1200x300)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/detour-left.png",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "131157259",
    "name": "On Side Road - Corflute (1200x300)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/on-side-road.png",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "12"
  },
  {
    "id": "102539315",
    "name": "Road Closed - Corflute (1200x300)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/road-closed.png",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "12"
  },
  {
    "id": "161059939",
    "name": "Merge Left - Corflute (1200x300)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/merge-left.png",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "139063909",
    "name": "Merge Right - Corflute (1200x300)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/merge-right.png",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "134225607",
    "name": "Prepare To Stop - Corflute (1200x300)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/prepare-to-stop.png",
    "price": "17.9",
    "artPrice": "15.5",
    "podQty": "12"
  },
  {
    "id": "191470684",
    "name": "Yellow Blank - Corflute (1200x300)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/yellow-blank-1200x300.png",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "24"
  },
  {
    "id": "166486283",
    "name": "Pedestrian Right Arrow - Corflute (1200x300)",
    "image_url": "https://www.uags.com.au/assets/full/CF123PR.jpg?20200707030629",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "109219224",
    "name": "Pedestrian Left Arrow - Corflute (1200x300)",
    "image_url": "https://storage.googleapis.com/sign-quote-system-public/pedestrians-left-1200x300.png",
    "price": "17.9",
    "artPrice": "14.5",
    "podQty": "6"
  },
  {
    "id": "142761965",
    "name": "Multi Message Frame",
    "image_url": "https://www.seton.net.au/media/catalog/product/y/4/y4044972-multi-message-sign-frame_1.jpg",
    "price": "38.1",
    "artPrice": "28",
    "podQty": "32"
  },
  {
    "id": "134944139",
    "name": "Multi Message Half Half Frame",
    "image_url": "https://www.uags.com.au/assets/full/MMF02.jpg?20200707030440",
    "price": "56",
    "artPrice": "38",
    "podQty": "8"
  },
  {
    "id": "186883830",
    "name": "Frame Legs",
    "image_url": "https://nationalsafetysigns.com.au/wp-content/uploads/2018/12/fixed-leg-for-frames.jpg",
    "price": "35.41",
    "artPrice": "15",
    "podQty": "92"
  },
  {
    "id": "153300675",
    "name": "Flags",
    "image_url": "https://www.jaybro.com.au/pub/media/catalog/product/cache/c2ce29bbfe5e027ff0a0343321477cfb/f/l/flags.jpg",
    "price": "3.6",
    "artPrice": "2.95",
    "podQty": "40"
  },
  {
    "id": "101020584",
    "name": "Boxed Chevrons - Metal",
    "image_url": "https://www.jaybro.com.au/pub/media/catalog/product/cache/c2ce29bbfe5e027ff0a0343321477cfb/t/5/t5-4.jpg",
    "price": "46.95",
    "artPrice": "46.95",
    "podQty": "6"
  },
  {
    "id": "195387361",
    "name": "Bollard Chevrons - Corflute (1500x450)",
    "image_url": "https://www.jaybro.com.au/pub/media/catalog/product/cache/c2ce29bbfe5e027ff0a0343321477cfb/4/0/40-t5-5cf-corflute-bollard-chevron.jpg",
    "price": "76.13",
    "artPrice": "46.95",
    "podQty": "8"
  },
  {
    "id": "174405303",
    "name": "Start/Stop Bats",
    "image_url": "https://www.jaybro.com.au/pub/media/catalog/product/cache/c2ce29bbfe5e027ff0a0343321477cfb/1/2/12-451ss-450mm-stop_slow-with-timber-handle.jpg",
    "price": "59",
    "artPrice": "64",
    "podQty": "4"
  }
]

module.exports = signDataFirstTruck;