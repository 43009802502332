import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './FetchQuote.css';
import axios from 'axios';
import ThankYouPage from './ThankYouPage';
import SubmittingPage from './SubmittingPage';
import signData2558T1 from '../signData2558T1';
import signData2558T2 from '../signData2558T2';
import signData2558T3 from '../signData2558T3';
import signData2509T1 from '../signData2509T1';
import signData2509T2 from '../signData2509T2';
import signData2509T3 from '../signData2509T3';
import signData2509T4 from '../signData2509T4';
import signData2509T5 from '../signData2509T5';
import signData2509T6 from '../signData2509T6';
import SignSection from './DeliverySignSection';
import useAutocomplete from '../hooks/useAutocomplete';

const defaultSrc =
  'https://nationalsafetysigns.com.au/wp-content/uploads/2015/10/RD176-Custom-Road-signs.png';

const initialState = {
  name: '',
  email: '',
  company: 'CCL',
  phoneNumber: '',
  deliveryDate: '',
  poNumber: '',
  address: '64 Duerdin St, Clayton VIC 3182',
  customRequirement: '',
  requestType: '',
};

const Form = ({ poNumber }) => {
  const [formData, setFormData] = useState(initialState);

  /* const [signs, setSigns] = useState(
    signData.map((sign) => ({ ...sign, quantity: 0 }))
  ); */
  const [signs, setSigns] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [comments, setComments] = useState({});
  const { addressInputRef } = useAutocomplete();
  const [requestType, setRequestType] = useState('');

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const [delDate, setDelDate] = useState('');
  const [totalTrucks, setTotalTrucks] = useState(0);

  useEffect(() => {
    console.log('Current poNumber:', poNumber);
    const fetchSignData = async () => {
      try {
        let signDataFile;
        let delDate;
        let totalTrucks;

        switch (poNumber) {
          case '2558-1':
            signDataFile = signData2558T1;
            setDelDate('2023-08-02');
            setTotalTrucks(3);
            break;
          case '2558-2':
            signDataFile = signData2558T2;
            setDelDate('2023-08-04');
            setTotalTrucks(3);
            break;
          case '2558-3':
            signDataFile = signData2558T3;
            setDelDate('2023-08-08');
            setTotalTrucks(3);
            break;
          case '2509-1':
            signDataFile = signData2509T1;
            setDelDate('2023-08-04');
            setTotalTrucks(6);
            break;
          case '2509-2':
            signDataFile = signData2509T2;
            setDelDate('2023-09-13');
            setTotalTrucks(6);
            break;
          case '2509-3':
            signDataFile = signData2509T3;
            setDelDate('2023-10-13');
            setTotalTrucks(6);
            break;
          case '2509-4':
            signDataFile = signData2509T4;
            setDelDate('2023-10-24');
            setTotalTrucks(6);
            break;
          case '2509-5':
            signDataFile = signData2509T5;
            setDelDate('2023-11-08');
            setTotalTrucks(6);
            break;
          case '2509-6':
            signDataFile = signData2509T6;
            setDelDate('2023-11-08');
            setTotalTrucks(6);
            break;
          default:
            console.log('Invalid poNumber:', poNumber);
            return;
        }

        /*
        console.log("Fetching data from:", signDataFile);
        const response = await axios.get(signDataFile);
        console.log("Response:", response);
        const fetchedSignData = response.data;
        console.log("Fetched Sign Data:", fetchedSignData);
        */

        setSigns(signDataFile.map((sign) => ({ ...sign, quantity: 0 })));
      } catch (error) {
        console.error('Error fetching sign data:', error);
        // Handle error if sign data fetching fails
      }
    };

    fetchSignData();
  }, [poNumber]);

  const signsBySize = {
    '600x600': [],
    '600x900': [],
    '1200x600': [],
    '1200x300': [],
    '300x600': [],
    Fitout: [],
    Accessories: [],
  };

  signs.forEach((sign) => {
    switch (true) {
      case sign.name.includes('600x600'):
        signsBySize['600x600'].push(sign);
        break;
      case sign.name.includes('600x900'):
        signsBySize['600x900'].push(sign);
        break;
      case sign.name.includes('1200x600'):
        signsBySize['1200x600'].push(sign);
        break;
      case sign.name.includes('1200x300'):
        signsBySize['1200x300'].push(sign);
        break;
      case sign.name.includes('300x600'):
        signsBySize['300x600'].push(sign);
        break;
      case sign.name.includes('Fitout'):
        signsBySize['Fitout'].push(sign);
        break;
      default:
        signsBySize['Accessories'].push(sign);
    }
  });

  const handleChange = (e) => {
    // Exclude deliveryDate and poNumber from being updated
    if (e.target.name !== 'deliveryDate' && e.target.name !== 'poNumber') {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleQuantityChange = (id, value) => {
    const updatedSigns = signs.map((sign) =>
      sign.id === id ? { ...sign, quantity: value } : sign
    );
    setSigns(updatedSigns);
  };

  // Update the handleCommentChange function to handle comment change
  const handleCommentChange = (id, comment) => {
    setComments((prevComments) => ({
      ...prevComments,
      [id]: comment,
    }));
  };

  const handleFileChange = (e) => {
    // console.log(e.target.files);
    const files = Array.from(e.target.files);
    let totalSize = 0;

    // Calculate the total size of selected files
    files.forEach((file) => {
      totalSize += file.size;
    });

    // Check if total size exceeds 10MB (10,000,000 bytes)
    if (totalSize > 10000000) {
      alert('Total file size exceeds the limit of 10MB.');
      return;
    }

    setSelectedFiles(files);
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);

    e.preventDefault();

    // Filter the signs based on quantity and include the comments
    const filteredSigns = signs
      .map((sign) => ({
        ...sign,
        comment: comments[sign.id] || '', // Include the comment or an empty string if not provided
      }))
      .filter((sign) => sign.quantity > -1);

    // Make sure filteredSigns is an array
    const filteredSignsArray = Array.isArray(filteredSigns)
      ? filteredSigns
      : [];

    const submitData = {
      ...formData,
      signs: filteredSignsArray,
      requestType,
    };

    console.log(submitData);

    const formDataWithFiles = new FormData();

    Object.entries(submitData).forEach(([key, value]) => {
      if (key === 'signs') {
        formDataWithFiles.append(key, JSON.stringify(value));
      } else {
        formDataWithFiles.append(key, value);
      }
    });

    console.log(formDataWithFiles);

    selectedFiles.forEach((file) => {
      formDataWithFiles.append('supportingFile', file);
    });

    console.log(formDataWithFiles);

    // Send a POST request to the backend server with files
    axios
      .post(
        'https://react-app-backend-mvlc.onrender.com/submit',
        formDataWithFiles,
        {
          headers: {},
        }
      )
      .then((response) => {
        console.log('Form submitted successfully');
        // Reset the form after successful submission
        setFormData(initialState);
        setSigns((prevState) =>
          prevState.map((sign) => ({
            ...sign,
            quantity: 0,
          }))
        );
        setSelectedFiles([]);
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });

    // Simulate a delay using setTimeout for demonstration purposes
    await new Promise((resolve) => setTimeout(resolve, 2000));

    setIsSubmitting(false);
    setSubmitted(true);
  };

  const handleReturn = () => {
    setSubmitted(false); // Reset the submitted state to false
  };

  const handleButtonClick = (e) => {
    setRequestType(e.target.value);
    setFormData({ ...formData, poNumber, deliveryDate: delDate });
  };

  // Check if the form is being submitted
  if (isSubmitting) {
    return <SubmittingPage requestType='delivery' />;
  }

  // Check if the form has been submitted
  if (submitted) {
    return (
      <ThankYouPage
        handleReturn={handleReturn}
        confirmationType='delivery'
      />
    );
  }

  return (
    <div className='container'>
      <div className='form-wrapper'>
        <div className='grid-item logo-container'>
          <img
            src='/llm-logo.jpg'
            alt='LLM and Co Logo'
          />
        </div>
        <div className='grid-item heading'>
          <h1>Delivery Confirmation for Order {poNumber.split('-')[0]}</h1>
          <h3>
            Pod Truck {poNumber.split('-')[1]} of {totalTrucks}
          </h3>
          <p></p>
        </div>

        <form
          onSubmit={handleSubmit}
          encType='multipart/form-data'
        >
          <div className='info-container gradient'>
            <div className='info-row'>
              <label htmlFor='name'>Checked By:</label>
              <input
                type='text'
                name='name'
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className='info-row'>
              <label htmlFor='company'>Company:</label>
              <input
                type='text'
                name='company'
                value={formData.company}
                onChange={handleChange}
                required
                disabled
              />
            </div>

            <div className='info-row'>
              <label htmlFor='email'>Email:</label>
              <input
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className='info-row'>
              <label htmlFor='phoneNumber'>Phone Number:</label>
              <input
                type='tel'
                name='phoneNumber'
                value={formData.phoneNumber}
                onChange={handleChange}
                maxLength={12}
                required
              />
            </div>

            <div className='info-split'>
              <div className='info-row split-item'>
                <label htmlFor='deliveryDate'>Delivery Date:</label>
                <input
                  type='date'
                  name='deliveryDate'
                  defaultValue={delDate}
                  onChange={handleChange}
                  required
                  disabled
                />
              </div>
              <div className='info-row split-item right-aligned'>
                <label htmlFor='poNumber'>PO Number:</label>
                <input
                  type='number'
                  name='poNumber'
                  value={parseInt(poNumber.split('-')[0])}
                  onChange={handleChange}
                  required
                  disabled
                />
              </div>
            </div>

            <div className='info-row'>
              <label htmlFor='address'>Address:</label>
              <input
                type='text'
                name='address'
                ref={addressInputRef}
                value={formData.address}
                onChange={handleChange}
                required
                disabled
              />
            </div>
          </div>
          {signs.length > 0 ? (
            <div className='signs-header'>
              <h3>Please enter the quantity of signs received</h3>
            </div>
          ) : (
            ''
          )}

          <div className='signs-container'>
            <SignSection
              title='600x600 Signs'
              signData={signsBySize['600x600']}
              handleQuantityChange={handleQuantityChange}
              handleCommentChange={handleCommentChange}
            />
            <SignSection
              title='600x900 Signs'
              signData={signsBySize['600x900']}
              handleQuantityChange={handleQuantityChange}
              handleCommentChange={handleCommentChange}
            />
            <SignSection
              title='1200x600 Signs'
              signData={signsBySize['1200x600']}
              handleQuantityChange={handleQuantityChange}
              handleCommentChange={handleCommentChange}
            />
            <SignSection
              title='1200x300 Signs'
              signData={signsBySize['1200x300']}
              handleQuantityChange={handleQuantityChange}
              handleCommentChange={handleCommentChange}
            />
            <SignSection
              title='300x600 Signs'
              signData={signsBySize['300x600']}
              handleQuantityChange={handleQuantityChange}
              handleCommentChange={handleCommentChange}
            />
            <SignSection
              title='Accessories'
              signData={signsBySize['Accessories']}
              handleQuantityChange={handleQuantityChange}
              handleCommentChange={handleCommentChange}
            />
          </div>

          <div className='description-container'>
            <h3>Extra notes</h3>
            <textarea
              name='customRequirement'
              placeholder='Please type here'
              value={formData.customRequirement}
              onChange={handleChange}
              rows='7'
            ></textarea>
          </div>
          {/*
          <div className="file-upload-container">
            <label htmlFor="supportingFile" className="file-upload-label">
              Add Supporting Images
            </label>
            <input
              type="file"
              id="supportingFile"
              name="supportingFile"
              accept=".jpg, .jpeg, .png, .pdf"
              className="file-upload-input"
              onChange={handleFileChange}
              multiple
            />
          */}
          {/* Render uploaded file items */}
          {/*}
            {selectedFiles.length > 0 && (
              <div className="uploaded-files">
                <h4>Added images:</h4>
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
            */}
          {/*
          </div> */}
          <div className='submit-container'>
            <button
              type='submit'
              id='placeOrderBtn'
              value='confirm'
              onClick={handleButtonClick}
            >
              Confirm Delivery
            </button>
          </div>
        </form>
        <div className='footer'>
          <img
            src='/LLM_Footer.png'
            alt='LLM and Co Company Information'
          />
        </div>
      </div>
    </div>
  );
};

export default Form;
