import { useEffect, useRef } from "react";

const useAutocomplete = () => {
  const addressInputRef = useRef(null);

  useEffect(() => {
    const autocompleteOptions = {
      // Other Autocomplete options...
      componentRestrictions: { country: "AU" }, // Restrict results to Australia
    };

    const autocompleteInstance = new window.google.maps.places.Autocomplete(
      addressInputRef.current,
      autocompleteOptions
    );

    // Set the types of place predictions to retrieve
    autocompleteInstance.setFields(["formatted_address"]);

    // Listen for place selection
    autocompleteInstance.addListener("place_changed", () => {
      console.log("Place changed"); // Add this console.log statement
      const selectedPlace = autocompleteInstance.getPlace();
      // const formattedAddress = selectedPlace.formatted_address;
      // Handle the selected address...
    });
  }, []);

  return { addressInputRef };
};

export default useAutocomplete;
