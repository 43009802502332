import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Quotes from "./pages/Quotes";
import './App.css';
import SingleQuote from "./pages/SingleQuote";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/traffic-management" replace />} /> 
        <Route path="/traffic-management" element={<Quotes />} />
        <Route path="/delivery-confirmation/:poNumber" element={<SingleQuote />} />
      </Routes>
    </Router>
  );
}

export default App;
