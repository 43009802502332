const signData = [
  {
    id: "151974864",
    name: "Road Work Ahead - Corflute (600x600)",
    image:
      "https://www.discountsafetysignsaustralia.com.au/wp-content/uploads/2017/02/bs2419-road-work-ahead.png.webp",
  },
  {
    id: "115010981",
    name: "End Road Work - Corflute (600x600)",
    image:
      "https://www.discountsafetysignsaustralia.com.au/wp-content/uploads/2017/02/bs2418-end-road.png.webp",
  },
  {
    id: "190607280",
    name: "Road Closed - Corflute (600x600)",
    image:
      "https://cdn.shopify.com/s/files/1/2439/4751/products/Road-Closed.jpg?v=1607738680&width=600",
  },
  {
    id: "142432692",
    name: "Road Closed Ahead - Corflute (600x600)",
    image:
      "https://storage.googleapis.com/sign-quote-system-public/road-closed-ahead-600x600.jpg",
  },
  {
    id: "146465575",
    name: "Local Traffic Only - Corflute (600x600)",
    image:
      "https://cdn.shopify.com/s/files/1/2439/4751/products/Local-Traffic-Only.jpg?v=1607738141&width=600",
  },
  {
    id: "187282206",
    name: "On Side Road - Corflute (600x600)",
    image:
      "https://cdn.shopify.com/s/files/1/2439/4751/products/OnSideRoad.jpg?v=1609976583&width=600",
  },
  {
    id: "103396737",
    name: "Footpath Closed - Corflute (600x600)",
    image:
      "https://www.uags.com.au/assets/full/CF66FC.jpg?20200707030727",
  },
  {
    id: "143900809",
    name: "Use Other Footpath - Corflute (600x600)",
    image:
      "https://storage.googleapis.com/sign-quote-system-public/use-other-footpath-600x600.jpg",
  },
  {
    id: "103838546",
    name: "Footpath Closed Ahead - Corflute (600x600)",
    image:
      "https://storage.googleapis.com/sign-quote-system-public/foothpath-closed-ahead-600x600.jpg",
  },
  {
    id: "178564930",
    name: "Watch Your Step - Corflute (600x600)",
    image: "https://www.uags.com.au/assets/full/CF66WYS.jpg?20200707030635",
  },
  {
    id: "135021531",
    name: "Detour - Corflute (600x600)",
    image:
      "https://www.uags.com.au/assets/full/CF66D.jpg?20200707030635",
  },
  {
    id: "115372677",
    name: "Arrow - Corflute (600x600)",
    image:
    "https://cdn.shopify.com/s/files/1/2439/4751/products/Arrow-Bold.jpg?v=1608158754&width=1100",
  },
  {
    id: "122083391",
    name: "Lane Status Straight Open - Corflute (300x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-open.jpg",
  },
  {
    id: "176965067",
    name: "Lane Status Straight Closed - Corflute (300x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-closed.jpg",
  },
  {
    id: "191541012",
    name: "Lane Status Left Open - Corflute (300x600)",
    image: "https://storage.googleapis.com/sign-quote-system-public/lane-status-left-open.jpg",
  },
  {
    id: "122994070",
    name: "Lane Status Right Open - Corflute (300x600)",
    image: "https://storage.googleapis.com/sign-quote-system-public/lane-status-right-open.jpg",
  },
  {
    id: "191546696",
    name: "Lane Status Left Closed - Corflute (300x600)",
    image: "https://storage.googleapis.com/sign-quote-system-public/lane-status-left-closed.jpg",
  },
  {
    id: "142885288",
    name: "Lane Status Right Closed - Corflute (300x600)",
    image: "https://storage.googleapis.com/sign-quote-system-public/lane-status-right-closed.jpg",
  },
  {
    id: "119792251",
    name: "Lane Status Straight Open Left Closed - Corflute (300x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-open-left-closed.jpg",
  },
  {
    id: "111783019",
    name: "Lane Status Straight Open Right Closed - Corflute (300x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-open-right-closed.jpg",
  },
  {
    id: "168938442",
    name: "Lane Status Straight Left Open - Corflute (300x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-left-open.jpg",
  },
  {
    id: "117435610",
    name: "Lane Status Straight Right Open - Corflute (300x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-right-open.jpg",
  },
  {
    id: "154742624",
    name: "Lane Status Straight Left Closed - Corflute (300x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-left-closed.jpg",
  },
  {
    id: "144745405",
    name: "Lane Status Straight Right Closed - Corflute (300x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/lane-status-straight-right-closed.jpg",
  },
  {
    id: "168828469",
    name: "Symbolic Digger Man - Corflute (600x900)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/symbolic-digger-man-600x900.jpg",
  },
  {
    id: "117769822",
    name: "40 Speed Ahead - Corflute (600x900)",
    image: "https://www.uags.com.au/assets/full/CF6940A.jpg?20200707030623",
  },
  {
    id: "139431163",
    name: "Speed Restriction 40km - Corflute (600x900)",
    image:
    "https://cdn.shopify.com/s/files/1/2439/4751/products/40kmSpeed.jpg?v=1614135886&width=493",
  },
  {
    id: "154748803",
    name: "Speed Restriction 60km - Corflute (600x900)",
    image:
    "https://cdn.shopify.com/s/files/1/2439/4751/products/60kmSpeed.jpg?v=1614135864&width=493",
  },
  {
    id: "152605267",
    name: "Speed Restriction 80km - Corflute (600x900)",
    image:
    "https://cdn.shopify.com/s/files/1/2439/4751/products/80km-Speed.jpg?v=1664109236&width=493",
  },
  {
    id: "143396669",
    name: "Speed Restriction 100km - Corflute (600x900)",
    image:
    "https://cdn.shopify.com/s/files/1/2439/4751/products/100km-Speed.jpg?v=1664108082&width=493",
  },
  {
    id: "154766142",
    name: "Road Work Ahead - Corflute (600x900)",
    image: "https://storage.googleapis.com/sign-quote-system-public/road-work-ahead-600x900.jpg",
  },
  {
    id: "162820621",
    name: "End Road Work - Corflute (600x900)",
    image: "https://storage.googleapis.com/sign-quote-system-public/end-road-work-600x900.jpg",
  },
  {
    id: "109133635",
    name: "Road Closed - Corflute (1200x600)",
    image:
    "https://www.uags.com.au/assets/full/CF126RC.jpg?20200707030727",
  },
  {
    id: "120924403",
    name: "Detour Ahead - Corflute (1200x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/detour-ahead-1200x600.jpg",
  },
  {
    id: "181268019",
    name: "Detour - Corflute (1200x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/detour.png",
  },
  {
    id: "100871194",
    name: "Road Work Ahead - Corflute (1200x600)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/roadwork-ahead.png",
  },
  {
    id: "183919202",
    name: "Detour Right - Corflute (1200x300)",
    image: "https://www.uags.com.au/assets/full/CF123DR.jpg?20200707030629",
  },
  {
    id: "137452490",
    name: "Detour Left - Corflute (1200x300)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/detour-left.png",
  },
  {
    id: "131157259",
    name: "On Side Road - Corflute (1200x300)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/on-side-road.png",
  },
  {
    id: "102539315",
    name: "Road Closed - Corflute (1200x300)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/road-closed.png",
  },
  {
    id: "161059939",
    name: "Merge Left - Corflute (1200x300)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/merge-left.png",
  },
  {
    id: "139063909",
    name: "Merge Right - Corflute (1200x300)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/merge-right.png",
  },
  {
    id: "134225607",
    name: "Prepare To Stop - Corflute (1200x300)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/prepare-to-stop.png",
  },
  {
    id: "191470684",
    name: "Yellow Blank - Corflute (1200x300)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/yellow-blank-1200x300.png",
  },
  {
    id: "166486283",
    name: "Pedestrian Right Arrow - Corflute (1200x300)",
    image: "https://www.uags.com.au/assets/full/CF123PR.jpg?20200707030629",
  },
  {
    id: "109219224",
    name: "Pedestrian Left Arrow - Corflute (1200x300)",
    image:
    "https://storage.googleapis.com/sign-quote-system-public/pedestrians-left-1200x300.png",
  },
  {
    id: "138947886",
    name: "Symbolic Traffic Controller - Corflute (600x600)",
    image: "https://www.uags.com.au/assets/full/CF66TC.jpg?20200707030632",
  },
  {
    id: "196045041",
    name: "Symbolic Worker - Corflute (600x600)",
    image:
    "https://www.safetyhq.com.au/assets/thumbL/USS-SW606-M.jpg?20230504122556",
  },
  {
    id: "174649300",
    name: "Speed Restriction 40km - Corflute (600x600)",
    image:
    "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2009_3.png",
  },
  {
    id: "109232277",
    name: "Speed Restriction 50km - Corflute (600x600)",
    image:
    "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2010_3.png",
  },
  {
    id: "160164356",
    name: "Speed Restriction 60km - Corflute (600x600)",
    image:
    "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2011_3.png",
  },
  {
    id: "127110790",
    name: "Speed Restriction 70km - Corflute (600x600)",
    image:
    "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2012_3.png",
  },
  {
    id: "107705971",
    name: "Speed Restriction 80km - Corflute (600x600)",
    image:
    "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2013_3.png",
  },
  {
    id: "126938612",
    name: "Speed Restriction 100km - Corflute (600x600)",
    image:
    "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2015_3.png",
  },
  {
    id: "141062889",
    name: "No Left Turn - Corflute (600x600)",
    image:
    "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2017_3.png",
  },
  {
    id: "159717497",
    name: "No Right Turn - Corflute (600x600)",
    image:
    "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/n/d/nd2018_3.png",
  },
  {
    id: "139687812",
    name: "Yellow Blank - Corflute (600x600)",
    image:
      "https://storage.googleapis.com/sign-quote-system-public/yellow-blank.png",
  },
  {
    id: "142761965",
    name: "Multi Message Frame",
    image:
    "https://www.seton.net.au/media/catalog/product/y/4/y4044972-multi-message-sign-frame_1.jpg",
  },
  {
    id: "134944139",
    name: "Multi Message Half Half Frame",
    image: "https://www.uags.com.au/assets/full/MMF02.jpg?20200707030440",
  },
  {
    id: "186883830",
    name: "Frame Legs",
    image:
      "https://nationalsafetysigns.com.au/wp-content/uploads/2018/12/fixed-leg-for-frames.jpg",
  },
  {
    id: "101998919",
    name: "Cone",
    image: "https://www.uags.com.au/assets/full/CONE700.jpg?20200707030440",
  },
  {
    id: "161333163",
    name: "6kg Bollard Bases",
    image:
      "https://www.southland.com.au/media/catalog/product/cache/1ad8a7b367eabf9134bbcff948670744/g/c/gc1035_jpg_5.jpg",
  },
  {
    id: "119978320",
    name: "T Top Bollards",
    image: "https://www.tools.com/assets/full/MAXS-BTB758P.jpg?20211105121612",
  },
  {
    id: "153300675",
    name: "Flags",
    image:
      "https://www.jaybro.com.au/pub/media/catalog/product/cache/c2ce29bbfe5e027ff0a0343321477cfb/f/l/flags.jpg",
  },
  {
    id: "101020584",
    name: "Boxed Chevrons - Metal",
    image:
      "https://www.jaybro.com.au/pub/media/catalog/product/cache/c2ce29bbfe5e027ff0a0343321477cfb/t/5/t5-4.jpg",
  },
  {
    id: "195387361",
    name: "Bollard Chevrons - Corflute (1500x450)",
    image:
      "https://www.jaybro.com.au/pub/media/catalog/product/cache/c2ce29bbfe5e027ff0a0343321477cfb/4/0/40-t5-5cf-corflute-bollard-chevron.jpg",
  },
  {
    id: "174405303",
    name: "Start/Stop Bats",
    image:
      "https://www.jaybro.com.au/pub/media/catalog/product/cache/c2ce29bbfe5e027ff0a0343321477cfb/1/2/12-451ss-450mm-stop_slow-with-timber-handle.jpg",
  },
  {
    id: "201355001",
    name: "Ute Fitout - All Signs For Ute",
    image: "https://storage.googleapis.com/sign-quote-system-public/ute-fitout.jpg"
  },
  {
    id: "201355002",
    name: "Truck Fitout - All Signs For Truck",
    image: "https://storage.googleapis.com/sign-quote-system-public/truck-fitout-new.jpg"
  }
];

export default signData;