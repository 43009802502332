import React from "react";
import "./SubmittingPage.css";

const SubmittingPage = ({ requestType }) => {
  let message = "Your request is being submitted. Please wait.";

  if (requestType === "delivery") {
    message = "Your delivery confirmation is being submitted. Please wait.";
  } else if (requestType === "other") {
    // Add more conditions for other request types if needed
    message = "Your custom request is being submitted. Please wait.";
  }

  return (
    <div className="submitting-page">
      <div className="loader"></div>
      <p>{message}</p>
    </div>
  );
};

export default SubmittingPage;
