import React, { useState, useEffect, useRef } from "react";
import "./Form.css";
import axios from "axios";
import ThankYouPage from "./ThankYouPage";
import SubmittingPage from "./SubmittingPage";
import signData from "../signData.js";
import SignSection from "./SignSection";
import useAutocomplete from "../hooks/useAutocomplete";

const defaultSrc =
  "https://nationalsafetysigns.com.au/wp-content/uploads/2015/10/RD176-Custom-Road-signs.png";

const initialState = {
  name: "",
  email: "",
  company: "",
  phoneNumber: "",
  deliveryDate: "",
  poNumber: "",
  address: "",
  customRequirement: "",
  requestType: "",
};

const Form = () => {
  const [formData, setFormData] = useState(initialState);
  const [signs, setSigns] = useState(
    signData.map((sign) => ({ ...sign, quantity: 0 }))
  );
  const [submitted, setSubmitted] = useState(false);
  const { addressInputRef } = useAutocomplete();
  const [requestType, setRequestType] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];

  const signsBySize = {
    "600x600": [],
    "600x900": [],
    "1200x600": [],
    "1200x300": [],
    "300x600": [],
    "Fitout": [],
    Accessories: [],
  };

  signs.forEach((sign) => {
    switch (true) {
      case sign.name.includes("600x600"):
        signsBySize["600x600"].push(sign);
        break;
      case sign.name.includes("600x900"):
        signsBySize["600x900"].push(sign);
        break;
      case sign.name.includes("1200x600"):
        signsBySize["1200x600"].push(sign);
        break;
      case sign.name.includes("1200x300"):
        signsBySize["1200x300"].push(sign);
        break;
      case sign.name.includes("300x600"):
        signsBySize["300x600"].push(sign);
        break;
        case sign.name.includes("Fitout"):
        signsBySize["Fitout"].push(sign);
        break;
      default:
        signsBySize["Accessories"].push(sign);
    }
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleQuantityChange = (id, value) => {
    const updatedSigns = signs.map((sign) =>
      sign.id === id ? { ...sign, quantity: value } : sign
    );
    setSigns(updatedSigns);
  };

  const handleFileChange = (e) => {
    // console.log(e.target.files);
    const files = Array.from(e.target.files);
    let totalSize = 0;

    // Calculate the total size of selected files
    files.forEach((file) => {
      totalSize += file.size;
    });

    // Check if total size exceeds 10MB (10,000,000 bytes)
    if (totalSize > 10000000) {
      alert("Total file size exceeds the limit of 10MB.");
      return;
    }

    setSelectedFiles(files);
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);

    e.preventDefault();
  
    const filteredSigns = signs.filter((sign) => sign.quantity > 0);
  
    // Make sure filteredSigns is an array
    const filteredSignsArray = Array.isArray(filteredSigns) ? filteredSigns : [];
  
    const submitData = {
      ...formData,
      signs: filteredSignsArray,
      requestType,
    };
  
    console.log(submitData);
  
    const formDataWithFiles = new FormData();

    Object.entries(submitData).forEach(([key, value]) => {
      if (key === "signs") {
        formDataWithFiles.append(key, JSON.stringify(value));
      } else {
        formDataWithFiles.append(key, value);
      }
    });

    console.log(formDataWithFiles);

    selectedFiles.forEach((file) => {
      formDataWithFiles.append("supportingFile", file);
    });
  
    console.log(formDataWithFiles);

    // Send a POST request to the backend server with files
    axios
      .post("https://react-app-backend-mvlc.onrender.com/submit", formDataWithFiles, {
        headers: {},
      })
      .then((response) => {
        console.log("Form submitted successfully");
        // Reset the form after successful submission
        setFormData(initialState);
        setSigns((prevState) =>
          prevState.map((sign) => ({
            ...sign,
            quantity: 0,
          }))
        );
        setSelectedFiles([]);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });

      // Simulate a delay using setTimeout for demonstration purposes
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setIsSubmitting(false);
      setSubmitted(true);
  };
  

  const handleReturn = () => {
    setSubmitted(false); // Reset the submitted state to false
  };

  const handleButtonClick = (e) => {
    setRequestType(e.target.value);
  };

  // Check if the form is being submitted
  if (isSubmitting) {
    return <SubmittingPage requestType="request" />;
  }

  // Check if the form has been submitted
  if (submitted) {
    return <ThankYouPage handleReturn={handleReturn} confirmationType="request" />;
  }

  return (
    <div className="container">
      <div className="form-wrapper">
        <div className="grid-item logo-container">
          <img src="/llm-logo.jpg" alt="LLM and Co Logo" />
        </div>
        <div className="grid-item heading">
          <h1>Road Traffic Management Signage</h1>
        </div>

        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="info-container gradient">
            <div className="info-row">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="info-row">
              <label htmlFor="company">Company:</label>
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>

            <div className="info-row">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="info-row">
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  maxLength={12}
                  required
                />
            </div>

            <div className="info-split">
              <div className="info-row split-item">
                <label htmlFor="deliveryDate">Delivery Date:</label>
                <input
                  type="date"
                  name="deliveryDate"
                  value={formData.deliveryDate}
                  onChange={handleChange}
                  min={currentDate}
                  required
                />
              </div>
              <div className="info-row split-item right-aligned">
                <label htmlFor="poNumber">PO Number:</label>
                <input
                  type="number"
                  min="0"
                  name="poNumber"
                  value={formData.poNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="info-row">
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                name="address"
                ref={addressInputRef}
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {signs.length > 0 ? (
            <div className="signs-header"><h3>Enter Quantity For Your Required Signs</h3></div>
          ) : (
            ""
          )}

          <div className="signs-container">
            <SignSection title="Fitouts" signData={signsBySize["Fitout"]} handleQuantityChange={handleQuantityChange} />
            <SignSection title="600x600 Signs" signData={signsBySize["600x600"]} handleQuantityChange={handleQuantityChange} />
            <SignSection title="600x900 Signs" signData={signsBySize["600x900"]} handleQuantityChange={handleQuantityChange} />
            <SignSection title="1200x600 Signs" signData={signsBySize["1200x600"]} handleQuantityChange={handleQuantityChange} />
            <SignSection title="1200x300 Signs" signData={signsBySize["1200x300"]} handleQuantityChange={handleQuantityChange} />
            <SignSection title="300x600 Signs" signData={signsBySize["300x600"]} handleQuantityChange={handleQuantityChange} />
            <SignSection title="Accessories" signData={signsBySize["Accessories"]} handleQuantityChange={handleQuantityChange} />
          </div>

          <div className="description-container">
            <h3>Have Custom Requirements?</h3>
            <textarea
              name="customRequirement"
              placeholder="Please add size, qty and description"
              value={formData.customRequirement}
              onChange={handleChange}
              rows="7"
            ></textarea>
          </div>
          <div className="file-upload-container">
            <label htmlFor="supportingFile" className="file-upload-label">
              Add Supporting Images
            </label>
            <input
              type="file"
              id="supportingFile"
              name="supportingFile"
              accept=".jpg, .jpeg, .png, .pdf"
              className="file-upload-input"
              onChange={handleFileChange}
              multiple
            />

            {/* Render uploaded file items */}
            {selectedFiles.length > 0 && (
              <div className="uploaded-files">
                <h4>Added images:</h4>
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="submit-container">
            <button
              type="submit"
              id="placeOrderBtn"
              value="order"
              onClick={handleButtonClick}
            >
              Place Order
            </button>
            <button
              type="submit"
              id="getQuoteBtn"
              value="quote"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
        </form>
        <div className="footer">
          <img src="/LLM_Footer.png" alt="LLM and Co Company Information" />
        </div>
      </div>
    </div>
  );
};

export default Form;
